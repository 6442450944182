// Helper Media Range function for specific frames 
@mixin media-range($range1, $range2 , $range3, $range4 , $index-element) {
  @media all and (min-width: $range1) and (max-width: $range2 - 1) and (min-height: $range3) and (max-height: $range4 - 1) {
    &:nth-child(#{$index-element}) {
      @content;
    }
  }
}

//this method is for calculated sizes, example the size of the white space between home-tile and ad, we can estimate the height smallest device and largest device. 
//we use this function to convert sum of values with different units to one value with the unit we choose
//$px is the value in px
//$vw is the value in vw
//$vh is the value in vh
//$unit is the final unit (px, vh, vw)
//$max-min is the minimum  or maximum  value in mobile device (min, max) 

@function convert-units($px, $vw, $vh, $unit, $max-min) {
  $val: 0;
  $px: strip-unit($px);
  $vh: strip-unit($vh);
  $vw: strip-unit($vw);

  @if $unit==vh {
    @if $max-min==max {
      $val: ($px * 0.15625 *1vh)+($vw * 0.59 * 1vh)+($vh * 1vh);
    }

    @if $max-min==min {
      $val: ($px * 0.10395 *1vh)+($vw * 0.46 * 1vh)+($vh * 1vh);
    }
  }

  @if $unit==vw {
    @if $max-min==max {
      $val: ($px * 0.325 *1vw)+($vh * 2.1739 * 1vw)+($vw * 1vw);
    }

    @if $max-min==min {
      $val: ($px * 0.208 *1vw)+($vh * 1.6949 * 1vw)+($vw * 1vw);
    }
  }

  @if $unit==px {
    @if $max-min==max {
      $val: ($vw * 4.80 *1px)+($vh * 9.26 * 1px)+($px * 1px);
    }

    @if $max-min==min {
      $val: ($vw * 3.2 *1px)+($vh * 6.4 * 1px)+($px * 1px);
    }
  }

  @return $val;

}

// maximising function 


@function maximising-function($min, $value , $max ) {
  $limited: calc(min(#{$value}, #{$max}));
  $limited: calc(max(#{$limited}, #{$min}));
  @return $limited;

}

// returns unitless values
@function strip-unit($number) {
  @if type-of($number)=='number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function relative-to-homepage($value, $axe) {
  @if ($axe==width) {
    @return percentage(strip-unit($value) / strip-unit($homePage-width-spec));
  }

  @if ($axe==height) {
    @return percentage(strip-unit($value) / strip-unit($homePage-height-spec));
  }
}

// if we have a fixed value 
@mixin fixed-property($prop , $value) {
  #{$prop}: $value !important;
}

// if we have a relative  value to device and it presents an independant class 
@mixin relative-property-to-device($prop , $value, $propdevice) {

  @if $propdevice==height {
    #{$prop}: $value * 100vh !important;
  }

  @if $propdevice==width {
    #{$prop}: $value * 100vw !important;
  }
}

// if we have a relative image or motif  to device and it's a part of another class 
@mixin relative-image-to-device($prop , $value , $average-in-spec, $average-in-device, $propdevice) {
  @if $propdevice==height {
    $average-in-spec : $value/$homePage-height-spec;
    #{$prop}: $average-in-spec * 30vh /$average-in-device !important;
  }

  @if $propdevice==width {
    $average-in-spec : $value/$homePage-width-spec;

    #{$prop}: 30vw * $average-in-spec /$average-in-device !important;
  }
}

@mixin calculated-relative-to-image($prop , $value, $average-in-device, $propdevice) {
  #{$prop}: $value /$average-in-device !important;
}

// when we have two different conditions(fix value  or relative to device), the prop value should be setted between this values with different average
@mixin approximate-property($prop , $value1, $value2, $prob ) {
  #{$prop}: calc(min(#{$value1}, #{$value2}) + (max(#{$value1}, #{$value2}) - min(#{$value1}, #{$value2}))*(1 - #{$prob})) !important;
}

// checking property and attributing a default value 
@function get-property($propiety, $lista, $defaultValue) {

  @if map-has-key($lista, $propiety) {
    @return map-get($lista, $propiety);
  }


  @else {
    @return $defaultValue ;
  }
}

@mixin globalfunction($classname) {
  @each $propa in $classname {

    $prop : get-property(prop, $propa, height);
    $type : get-property(type, $propa, $default);
    $value : get-property(value, $propa, black);

    @if type-of(get-property(value, $propa, ()))==map {
      $value: ()
    }

    ;
    $propdevice :get-property(propdevice, $propa, width);
    $height-100vh-device :get-property(height-100vh-device, $propa, '');
    $height-device : get-property(height-device, $propa, '');
    $min-width : get-property(min-width, $propa, $zero);
    $max-width : get-property(max-width, $propa, $max-media);
    $min-height : get-property(min-height, $propa, $zero);
    $max-height : get-property(max-height, $propa, $max-media);
    $index-element : get-property(index-element, $propa, #{n});

    $average-in-spec : $value/$homePage-height-spec;
    $average-in-100vh-device : $height-100vh-device/$height-device;

    @include media-range($min-width, $max-width, $min-height, $max-height, $index-element) {

      @if $default-configuration==false {

        //type 1 : the value is fix , the unit can be in px, em, cm ...
        @if $type ==$fix {
          @if ($classname !=$home-tile-legend-icon and $classname !=$home-tile-legend-label and $classname !=$menu-icon and $classname !=$menu-icon-svg and $classname !=$home-tile-legend) {
            @include fixed-property($prop , $value);
          }

          //  child elements
          @if ($classname ==$home-tile-legend-icon ) {
            &:nth-child(#{$index-element}) {
              .home-tile-legend-icon {
                @include fixed-property(#{$prop}, #{$value});
              }
            }
          }

          @if ($classname ==$home-tile-legend-label ) {
            &:nth-child(#{$index-element}) {
              .home-tile-legend-label {
                @include fixed-property(#{$prop}, #{$value});
              }
            }
          }

          @if ($classname ==$menu-icon ) {
            &:nth-child(#{$index-element}) {
              .menu-icon {
                @include fixed-property(#{$prop}, #{$value});
              }
            }
          }

          @if ($classname ==$menu-icon-svg) {
            &:nth-child(#{$index-element}) {
              @include fixed-property(#{$prop}, #{$value});

              .menu-icon {

                .st0,
                .st1 {
                  @include fixed-property(#{$prop}, #{$value});
                }
              }
            }
          }

          @if ($classname ==$home-tile-legend ) {
            &:nth-child(#{$index-element}) {
              .home-tile-legend {
                @include fixed-property(#{$prop}, #{$value});
              }
            }
          }


        }

        //type2 : the unitless value is relative to device height or width, it's set between 0 and 1
        @if $type ==$relative-to-device {
          @include relative-property-to-device($prop , $value, $propdevice);
        }

        //type3 : it renders an estimated value of a part of an image that has not its own class

        @if $type ==$relative-to-image {

          @include relative-image-to-device($prop , $value, $average-in-spec, $average-in-100vh-device, $propdevice);
        }

        @if $type ==$calculated-relative-to-image {

          @include calculated-relative-to-image($prop , $value, $average-in-100vh-device, $propdevice);
        }

        //type4 : it renders an estimated value of a part of an image that has not its own class
        @if $type ==$multi-values {
          @if type-of(get-property(value, $propa, black))==map {
            $value1: get-property(value1, get-property(value, $propa, 1px), '');
            $value2: get-property(value2, get-property(value, $propa, 1px), '');
            $prob: get-property(prob, get-property(value, $propa, 0), '');
            @include approximate-property($prop , $value1 , $value2 , $prob )
          }

        }


        //type5 : default values 
        @if $type ==$default {
          @if $classname !=$home-tile-legend-icon and ($classname !=$home-tile-legend-label )and ($classname !=$menu-icon) and ($classname !=$menu-icon-svg ) and ($classname !=$home-tile-legend) {
            @include fixed-property($prop , $value);
          }

          // child elements
          @if ($classname ==$home-tile-legend-icon ) {
            &:nth-child(#{$index-element}) {
              .home-tile-legend-icon {
                @include fixed-property(#{$prop}, #{$value});
              }
            }
          }

          @if ($classname ==$home-tile-legend) {
            &:nth-child(#{$index-element}) {
              .home-tile-legend {
                @include fixed-property(#{$prop}, #{$value});
              }
            }
          }

          @if ($classname ==$home-tile-legend-label ) {
            &:nth-child(#{$index-element}) {
              .home-tile-legend-label {
                @include fixed-property(#{$prop}, #{$value});
              }
            }
          }

          @if ($classname ==$menu-icon) {
            &:nth-child(#{$index-element}) {
              .menu-icon {
                @include fixed-property(#{$prop}, #{$value});
              }
            }
          }

          @if ($classname ==$menu-icon-svg) {

            &:nth-child(#{$index-element}) {
              @include fixed-property(#{$prop}, #{$value});

              .menu-icon {

                .st0,
                .st1 {
                  @include fixed-property(#{$prop}, #{$value});
                }
              }
            }
          }


        }


      }

      @if $default-configuration==true {

        @if $type ==$default {
          @if ($classname !=$home-tile-legend-icon and $classname !=$home-tile-legend-label and $classname !=$menu-icon and $classname !=$menu-icon-svg and $classname !=$home-tile-legend) {
            @include fixed-property($prop , $value);
          }

          //child elements      
          @if ($classname ==$home-tile-legend-icon ) {
            &:nth-child(#{$index-element}) {
              .home-tile-legend-icon {
                @include fixed-property(#{$prop}, #{$value});
              }
            }
          }

          @if ($classname ==$home-tile-legend-label ) {
            &:nth-child(#{$index-element}) {
              .home-tile-legend-label {
                @include fixed-property(#{$prop}, #{$value});
              }
            }
          }
        }

        @if ($classname ==$menu-icon) {
          &:nth-child(#{$index-element}) {
            .menu-icon {
              @include fixed-property(#{$prop}, #{$value});
            }
          }
        }

        @if ($classname ==$menu-icon-svg) {
          &:nth-child(#{$index-element}) {
            @include fixed-property(#{$prop}, #{$value});

            .menu-icon {

              .st0,
              .st1 {
                @include fixed-property(#{$prop}, #{$value});
              }
            }
          }
        }

        @if ($classname ==$home-tile-legend) {
          &:nth-child(#{$index-element}) {
            .home-tile-legend {
              @include fixed-property(#{$prop}, #{$value});
            }
          }
        }


      }
    }

  }
}

@mixin responsive-class($classname) {
  // home-toolbar class

  @if ($classname =='home-toolbar') {
    @include globalfunction($home-toolbar);
  }

  // home-toolbar svg class

  @if ($classname =='ht-button-svg') {
    @include globalfunction($ht-button-svg);
  }


  // home-tiles class 
  @if ($classname=='home-tiles') {
    @include globalfunction($home-tiles);
  }

  // home-tiles class 
  @if ($classname=='home-tiles') {
    @include globalfunction($home-tiles);
  }

  // home-tile class 
  @if ($classname=='home-tile') {
    @include globalfunction($home-tile);
  }

  @if ($classname=='home-content') {

    @include globalfunction($home-content);

  }

  // home-tile-legend class
  @if ($classname=='home-tile-legend') {

    @include globalfunction($home-tile-legend);

  }

  // home-tile-legend-icon class
  @if ($classname=='home-tile-legend-icon') {

    @include globalfunction($home-tile-legend-icon);

  }

  // home-tile-legend-label class

  @if ($classname=='home-tile-legend-label') {

    @include globalfunction($home-tile-legend-label);

  }


  // menu-icon class

  @if ($classname=='menu-icon') {
    @include globalfunction($menu-icon);
  }

  // menu-icon-svg class

  @if ($classname=='menu-icon-svg') {
    @include globalfunction($menu-icon-svg);
  }

  // menu-text class
  @if ($classname=='menu-text') {

    @include globalfunction($menu-text);

  }

  // ht-button class is under hometoolbar class
  @if ($classname=='ht-button') {
    @include globalfunction($ht-button);
  }

  // ht-icon class is under ht-button class
  @if ($classname=='ht-icon') {
    @include globalfunction($ht-icon);
  }


}